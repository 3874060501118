<template>
  <div>
    <div class="transfer">
      <el-select
        v-model="invitedUsers"
        multiple
        filterable
        allow-create
        value-key="uuid"
        default-first-option
        placeholder="Add User"
        class="users-search-input"
      >
        <el-option
          v-for="user in notInvitedUsers"
          :key="user.id"
          :label="user.name"
          :value="user"
        />
      </el-select>
    </div>
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        @click="close"
      >
        Cancel
      </el-button>
      <el-button
        type="primary"
        @click="inviteUsers"
      >
        Invite
      </el-button>
    </div>
  </div>
</template>

<script>
import { UserRepository } from '@timeragent/core';

export default {
  name: 'UserSearchWindow',
  props: {
    pokerSession: {
      type: Object,
      require: false,
      default: () => null,
    },
  },
  data() {
    return {
      invitedUsers: [],
      restUsers: [],
      userRepository: new UserRepository(),
    };
  },
  computed: {
    users() {
      return this.userRepository.dataset;
    },
    notInvitedUsers() {
      if (this.pokerSession.users.length) {
        return this.users.filter(user => !this.pokerSession.users.map(u => u.uuid).includes(user.uuid));
      }

      return this.users;
    },
  },
  created() {
    this.userRepository.many({ organizationUuid: this.$route?.params?.organizationUuid });
  },
  methods: {
    close() {
      this.$emit('close');
    },
    inviteUsers() {
      this.$emit('invite-users', this.invitedUsers);
      this.invitedUsers = [];
    },
  },
};
</script>

<style scoped>
  .users-search-input {
    width: 100%;
  }
  .transfer {
    margin-top: 20px;
  }
  .dialog-footer {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
  }
</style>
